import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js' 
import "font-awesome/css/font-awesome.min.css";
import SvgImgIcons from "../src/components/IconTemplate/SvgImgIcons.vue"
import store from "./util/store.js";

//defined as global component
Vue.component('VueFontawesome', require('vue-fontawesome-icon/src/components/VueFontawesome.vue').default);
Vue.component('svg-img-icons',SvgImgIcons); 


Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(require('vue-cookies'))
Vue.use(store);

new Vue({
  el: '#app',
  router,
  icons,
  store,
  template: '<App/>',
  components: {
    App
  },
})
