import Vue from 'vue'
import { ValidationProvider, extend, ValidationObserver} from 'vee-validate';
import { required, email} from 'vee-validate/dist/rules';

extend('email', {
    ...email,
    // the values param is the placeholders values
    message: values => `無效的${values}`
});
extend('required', {
    ...required,
    message: values => `${values}為必填欄位`
});
extend("anyBrand", {
    validate: value => {
        // console.log(`anyBrand`, value);
        return value;
    }
})

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
