<template>
  <router-view></router-view>
</template>

<script>
import Vue from 'vue'
//import * as firebase from "firebase";
//import store from "./store";
import VueCookies from 'vue-cookies';
import VuejsDialog from 'vuejs-dialog';
import "./util/vee-validate";

// const configOptions = {
//     apiKey: "AIzaSyA3D31Y68oL0ne9Ktl6rd1dVScBY9XGtpc",
//     authDomain: "getchee-qa-aa7fc.firebaseapp.com",
//     databaseURL: "https://getchee-qa-aa7fc.firebaseio.com",
//     projectId: "getchee-qa-aa7fc",
//     storageBucket: "getchee-qa-aa7fc.appspot.com",
//     messagingSenderId: "177893020967",
//     appId: "1:177893020967:web:5c1ecf12bfdef65a360502",
//     measurementId: "G-8PDLXQNG4K"
// };

// firebase.initializeApp(configOptions);

Vue.use(VueCookies);
Vue.use(VuejsDialog);

// firebase.auth().onAuthStateChanged(user => {
//   store.dispatch("fetchUser", user);
// });


//echart  
import ECharts from 'vue-echarts'
import { use } from 'echarts/core' 
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart,GaugeChart } from 'echarts/charts'
import { GridComponent, TooltipComponent ,LegendComponent } from 'echarts/components' 
use([ CanvasRenderer, BarChart,GaugeChart, GridComponent, TooltipComponent,LegendComponent  ]); 
Vue.component('v-chart', ECharts)


export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
