import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

let store = new Vuex.Store({
    state: {
        token: "",
        userName: "",
        tryFree: false,
        advancedReportFormData: {
            name: "",
            email: "",
            reports: [],
        }
    },
    plugins: [createPersistedState()],
    mutations: {
        setUser(state, data) {
            //console.log("setUser" + data);
            state.userName = data.UserName;
            state.token = data.Token;
        },
        clearUser(state) {
            //console.log("clearUser");
            state.userName = undefined;
            state.token = undefined;
        },
        setTryFree(state) {
            state.tryFree = true;
        },
        clearTryFree(state) {
            state.tryFree = false;
        },
        set(state, [variable, value]) {
            state[variable] = value
        },
        clearAdvancedReportFormData(state) {
            state.advancedReportFormData.name = "";
            state.advancedReportFormData.email = "";
            state.advancedReportFormData.reports = [];
        }
    },
    modules: {}
});

export default store;